import { Routes, Route, Link, Navigate } from "react-router-dom";


/** ALL ROUTE ELEMENTS */
import AppHome from "pages/AppHome";
import Team from "pages/public/Team";
import Features from "pages/public/Features";
import Technology from "pages/public/Technology";
import ContactUs from "pages/public/ContactUs";
import Privacy from "pages/public/Privacy";
import Cookies from "pages/public/Cookies";
import TnCs from "pages/public/TnCs";




/** ALL ROUTE ELEMENTS END */


const AllRoutes = () => {
    return ( 
        <>        
            <Routes>
                
                <Route path="/" element={ <AppHome /> } />
                <Route path="/home" element={ <Navigate to='/' /> } />

                <Route path="/team" element={ <Team /> } />

                <Route path="/technology" element={ <Technology /> } />
                <Route path="/tech" element={ <Navigate to='/tech' /> } />

                <Route path="/features" element={ <Features /> } />

                <Route path="/contact-us" element={ <ContactUs /> } />
                <Route path="/contact" element={ <Navigate to='/contact-us' /> } />

                <Route path="/privacy" element={ <Privacy /> } />
                <Route path="/cookies" element={ <Cookies /> } />
                <Route path="/terms-and-condition" element={ <TnCs /> } />
                <Route path="/tncs" element={ <Navigate to='/terms-and-condition' /> } />
                {/* AUTH ROUTES END */}

            </Routes>

        </>
     );
}



 
export default AllRoutes;