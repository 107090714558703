import { useState } from "react";
import styled from "styled-components";

import HeroSliderV1 from "library/heroslider/HeroSliderV1";

const PageWrapper = styled.div`
margin: 0 auto;
width: 100%;
height: auto;
display: flex;
position: relative;

.hero-area {
    margin: 0 auto;
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
}
`;

const Welcome = () => {

    const [ slideData, setSlideData ] = useState([
        {
          image: 'hero-v1/img-1.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-2.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-3.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-4.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-5.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"  
        },
        {
          image: 'hero-v1/img-6.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-7.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-8.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-9.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
        {
          image: 'hero-v1/img-10.jpg',
          title: "Next-generation social platform for small businesses",
          description: "BiznesXpo provides a digital office for small businesses to brand, find more customers & network. Anytime. Anywhere. Free!"
        },
      ]);
    

    return (    
            <PageWrapper>
                <div className="hero-area">
                <HeroSliderV1 data={ [ ...slideData ] } maxTransSlides={ 5 } />
                </div>
                <div className="page-content__main"></div>
            </PageWrapper>
        

     );
}
 
export default Welcome;